import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Chrome from "@uiw/react-color-chrome";
import { GithubPlacement } from "@uiw/react-color-github";
import secureLocalStorage from "react-secure-storage";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { auth } from "../helper/firebaseClient";
import data from "@emoji-mart/data/sets/15/native.json";
import Picker from "@emoji-mart/react";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
  onSnapshot,
  collection,
  addDoc,
} from "firebase/firestore";
import { db } from "../helper/firebaseClient";
import { useDepartment } from "../stores/departmentStore";
import useAuth from "../stores/authStore";

const AddDept = ({ source }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [deptName, setDeptName] = useState("");
  const [hex, setHex] = useState("#4F46E5");
  const listDepartments = useDepartment((state) => state.listDepartments);
  const [loading, setLoading] = useState(false);
  const [emoji, setEmoji] = useState(null); // State to hold selected emoji
  const profileData = useAuth((state) => state.profileData);

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const handleEmojiSelect = (emoji) => {
    setEmoji(emoji.native); // Update state with selected emoji
  };

  const addDepartment = async () => {
    try {
      const token = await auth.currentUser.getIdToken();

      // Replace spaces with underscores in department name
      const formattedDeptName = deptName.replace(/ /g, "_");

      let body = {
        name: formattedDeptName,
        metadata: {
          bg: hex,
          border: "#B7B6C2",
        },
        idToken: token,
        emoji: emoji,
      };

      let addDept = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/dept/createDepts`,
        body
      );

      console.log(addDept);

      if (addDept) {
        await listDepartments();
        // await setDoc(doc(db, "departmentChats", addDept.data.id), {
        //   lastMessage: "",
        //   date: serverTimestamp(),
        // });
        const deptChatRef = doc(db, "departmentChats", "organisations");

        // Ensure the parent document exists (optional but recommended)
        await setDoc(deptChatRef, { placeholder: true }, { merge: true });

        // Reference the organisation subcollection under the departmentChats document
        const orgCollectionRef = collection(deptChatRef, profileData.org);

        await addDoc(orgCollectionRef, {
          name: formattedDeptName,
          lastMessage: {
            date: serverTimestamp(),
            text: "",
          },
        });
        await setDoc(doc(db, "chats", addDept.data.id), { messages: [] });
      }
      closeDialog();
    } catch (error) {
      console.log("error occurew while adding dept", error);
    }
  };

  return (
    <div className="">
      {source === "settings" ? (
        <button
          onClick={openDialog}
          className="bg-[#5E5ADB] text-white text-sm rounded-lg py-2 px-3"
        >
          Add New Department
        </button>
      ) : (
        <button
          onClick={openDialog}
          title="create folder"
          className="text-2xl rounded-lg px-3 mx-4 text-center text-purple-600 border border-purple-600"
        >
          +
        </button>
      )}

      <Dialog
        open={isOpen}
        onClose={closeDialog}
        PaperProps={{
          style: {
            borderRadius: "5px",
          },
        }}
      >
        <DialogTitle>New Department</DialogTitle>
        <DialogContent
          style={{
            backgroundColor: "#F7F8FA",
          }}
        >
          <div className="my-2 w-full py-2">
            <span className="w-[462px]">
              <p className="text-gray-700">Department Name</p>
              <input
                className="w-full border border-gray-300 rounded-md my-2 px-2 py-1"
                type="text"
                value={deptName}
                onChange={(e) => setDeptName(e.target.value)}
              />
            </span>

            <div className="w-full flex gap-6">
              <span>
                <p className="text-gray-700 my-2">
                  Department Icon {emoji && `: ${emoji}`}
                </p>
                <Picker
                  data={data}
                  set="native"
                  onEmojiSelect={handleEmojiSelect}
                  theme="light"
                  perLine={7}
                />
              </span>

              <span>
                <p className="text-gray-700 my-2">Department Color</p>
                <Chrome
                  color={hex}
                  style={{ width: "100%", margin: "auto" }}
                  placement={GithubPlacement.Right}
                  onChange={(color) => {
                    setHex(color.hexa);
                  }}
                />
              </span>
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ padding: "10px" }}>
          <button
            className="px-2 py-1 mx-2 rounded-lg shadow-sm border border-gray-300"
            onClick={closeDialog}
            color="primary"
          >
            Cancel
          </button>
          <button
            className="flex gap-2 items-center px-2 py-1 rounded-lg shadow-sm bg-[#5E5ADB] text-white"
            onClick={addDepartment}
          >
            Add new department
            {loading && <CircularProgress size={20} color="inherit" />}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddDept;
